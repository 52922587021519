import React, { useEffect, useRef } from 'react';

    function Blocks(props) {
        const blockRef = useRef(null);

        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        blockRef.current.classList.add('animate');
                        observer.unobserve(blockRef.current); // Stop observing once the animation starts
                    }
                });
            });
        
            if (blockRef.current) {
                observer.observe(blockRef.current);
            }

            return () => {
                if (blockRef.current) {
                    observer.unobserve(blockRef.current);
                }
            };
        }, []);

        return (
            <div className="block" ref={blockRef}>
                <div>
                    <h2>{props.blockTitle}</h2>
                </div>
                <hr />
                <div>
                    <p>{props.blockText}</p>
                </div>
            </div>
        );
    };

export default Blocks;