import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Heading from "./Heading";
import Footer from "./Footer";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Games from "../pages/Games.js";
import Websites from "../pages/Websites.js";
import Home from "../pages/Home.js";
import Contact from "../pages/Contact.js";
import NoPage from "../pages/NoPage.js";

function App() {
  return (
    
      <div className="App">
        <Heading />
        <BrowserRouter>
        <Routes>
          <Route index element ={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Websites" element={<Websites />} />
          <Route path="/Games" element={<Games />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/submit_form.php" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        </BrowserRouter>
        <Footer />
      </div>
      
  );
}

export default App;
