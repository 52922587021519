import React, { useEffect, useRef } from 'react';

function Card(props) {
    const blockRef = useRef(null);

 useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        blockRef.current.classList.add('animate');
                        observer.unobserve(blockRef.current); // Stop observing once the animation starts
                    }
                });
            });
        
            if (blockRef.current) {
                observer.observe(blockRef.current);
            }

            return () => {
                if (blockRef.current) {
                    observer.unobserve(blockRef.current);
                }
            };
        }, []);

    return (
    <div className="card" ref={blockRef}>
        <div>
            <h2>{props.sitename}</h2>
            </div>
            <hr />
        <div>
            <a href={props.siteadress} className={props.sitestatus} > {props.sitestatus === "disabled" ? "Visit - taken down" : "Visit" }</a>
        </div>
        <div>
            <p>{props.sitetech}</p>
            <p>{props.sitetype}</p>
        </div>
    </div>
    );
}

export default Card;




// import React from "react";

// function Card(props) {
//     return (
//     <div className="card">
//         <div>
//             <h2>{props.sitename}</h2>
//             </div>
//             <hr />
//         <div>
//             <a href={props.siteadress} className={props.sitestatus} > {props.sitestatus === "disabled" ? "Visit - taken down" : "Visit" }</a>
//         </div>
//         <div>
//             <p>{props.sitetech}</p>
//             <p>{props.sitetype}</p>
//         </div>
//     </div>
//     );
// }

// export default Card;