const blockContent = [
    {
        id: 1,
        blockTitle: "Professional Photography:",
        blockText: "Since 2010, I’ve been snapping professional product and advertising photos for companies allover Europe and the US, making sure your products look their best and scream your brand’s message (because looking good is half the battle).",
        
    },
    {   
        id: 2,
        blockTitle: "Web Design & Development:",
        blockText: "It all started when I needed a website for my photography business. I dove into web development, learning HTML basics through countless Google searches. After several years and eight redesigns, I settled on a modified WordPress theme due to time constraints. To avoid boredom, I learned various programming languages and frameworks for fun. Although I never intended to use these skills professionally, friends' requests kept coming. I embraced the challenges, creating diverse websites, including online shops, appointment systems for salons, business storefronts, custom blogs (with backends and admin areas), and form-to-PDF scripts. Some sites are still live, while others have disappeared due to business changes. While building this website, I had the idea to add a small game from scratch. Feel free to try it out! I learned a lot and plan to create more games soon.",
        
    },
    {   
        id: 3,
        blockTitle: "3D Modeling & Animation:",
        blockText: " Nearly 18 years ago, I stumbled upon 3D modeling, starting as a hobby that somehow turned into a decent revenue source. From concept to final render, I bring ideas to life with high-quality 3D modeling, rendering, and animation. ",
        
    }
]

export default blockContent;