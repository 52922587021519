import React from "react";
import wpSites from "../components/wp_sites.js";
import Card from "../components/Card";

function Websites() {
    return (
        <div className="container">
            {wpSites.map((wpSites) => (<Card key={wpSites.id} sitestatus={wpSites.siteStatus} sitename={wpSites.name} siteadress={wpSites.siteUrl} sitetech={wpSites.builtWith} sitetype={wpSites.siteType} />
        ))}
    </div>
);
}

export default Websites;