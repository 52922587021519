import React from "react";
import * as THREE from 'three';



const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);


function Fb3d() { 



    return (
        <div className="fb3d d-flex" id="fb3d">
            <canvas>

            </canvas>
        </div>
    );

}

export default Fb3d;