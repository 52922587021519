import React, { useState } from "react";
import axios from "axios";

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [honeypot, setHoneypot] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (honeypot) {
      setResponseMessage('Spam detected');
      return;
    }
    try {
      const response = await axios.post('/submit_form.php', {
        name,
        email,
        message
      });
      setResponseMessage(response.data.message);
    } catch (error) {
      setResponseMessage('There was an error submitting the form.');
      console.error(error);
    }
  };

  return (
    <div className="container">
    <div className="Contact">
      <div className="contact-form">
          <h1>Contact</h1>
          <hr />
        <p>Reach by phone <a href="tel:0040740894016">Tap to call</a></p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />

          <label htmlFor="email">Email:</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />

          <label htmlFor="message">Message:</label>
          <textarea 
            id="message" 
            name="message" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            required 
          ></textarea>

          <input 
            type="text" 
            id="honeypot" 
            name="honeypot" 
            value={honeypot} 
            onChange={(e) => setHoneypot(e.target.value)} 
            style={{ display: 'none' }} 
          />

          <button type="submit">Submit</button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
      </div>
      </div>
  );
}

export default Contact;
