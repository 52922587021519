import React from "react";
import classNames from "classnames";

function ScoreCounter(props) { 
  

  return (
    <div className={classNames('grid-item wrap')} id="score">
      {/* <p>Random Number: {props.number !== null ? props.number : 'None'}</p>
      <p>Key Pressed: {props.keyPressed !== null ? props.keyPressed : 'None'}</p> */}
      <h2>Score: {props.score}</h2>
    </div>
  );
}

export default ScoreCounter;