import React from "react";
import me from "../images/me.jpg";
import Blocks from "../components/Blocks";
import blockContent from "../components/block_content";

function MainPage() {
  return (
    <div className="Main">
      <div className="Intro">
        <h1>Welcome to My Portfolio / Sandbox / Playground / Testbench</h1>
        <hr />
        <h2>Ciprian Kis:</h2>
        <p>Web Designer, Developer, Visual Artist </p>
        <h6>*This website is always under construction.</h6>
      </div>
      <div className="About">
        <div className="col1">
          <div className="imgBorder">
            <img src={me} className="MePic" alt="Ciprian Kis avatar" />
          </div>
        </div>
        <div className="col2">
          <h2>About Me:</h2>
          <hr />
          <p>
            With over fifteen years of making products and ads look fabulous
            through the magic of photography (basically, I make stuff look
            good), and a decade of web development (mostly involving intense
            sessions of Googling), I'm your go-to person for all things
            digital.
            <br />
            I specialize in crafting awesome(at least, my friends say they look cool) tailored websites from scratch
             that are also highly
            functional.
            <br /> But wait, there's more!
            <br /> My bag of tricks also includes 3D modeling, rendering,
            animation, and VFX.
            <br />
            When it comes to web development, I dabble in both front-end and
            back-end, juggling HTML, JavaScript, CSS, PHP, MySQL, React JS, and
            Bootstrap.. ++ 
            <br /> I'm always adding new skills to my repertoire to stay ahead
            in this ever-evolving tech world.
            <br />
            So, whether you need a website that dazzles or some slick 3D
            animations, I'm here to make it happen with a smile.
          </p>
        </div>
      </div>

      {blockContent.map((block) => (
        <Blocks key={block.id} blockTitle={block.blockTitle} blockText={block.blockText} />
      ))}
    </div>
  );
}

export default MainPage;
