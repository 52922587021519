import React from "react"
import Lin from "../images/Lin.svg";
import Ins from "../images/Ins.svg";

var currentYear = new Date().getFullYear()

function Footer() {
    return <footer className="App-footer">
      <p>Created by Ciprian Kis - © {currentYear}</p>
      <p><a href="https://www.linkedin.com/in/ciprian-kis-1b571980/"><img id="socialIcon" src={Lin} /></a>
        <a href="https://www.instagram.com/cipriankis_com/"><img id="socialIcon" src={Ins} /></a></p>
      </footer>;
}

export default Footer;