const wpSites = [
    {
        id: 1,
        siteStatus: "enabled",
        name: "www.CiprianKis.com",
        siteUrl: "https://Cipriankis.com",
        builtWith: "For now, it's just a Customised Wordpress Theme but it's been built and rebuilt and changed at least 6 times over the years, it was always built from scratch before",
        siteType: "Photography Portfolio",
    },
    {   
        id: 2,
        siteStatus: "enabled",
        name: "www.AdrianTaxAgent.com",
        siteUrl: "https://adriantaxagent.com",
        builtWith: "Customised Wordpress Theme - Includes registration form, signature input field, filled out PDF",
        siteType: "Local Business, Tax Agent",
    },
    {   
        id: 3,
        siteStatus: "enabled",
        name: "www.ClyaBeauty.com",
        siteUrl: "https://clyabeauty.com",
        builtWith: "From scratch - HTML, CSS, JS",
        siteType: "Local Business, Beauty salon and Beauty courses",
    },
    {   
        id: 4,
        siteStatus: "disabled",
        name: "www.ecommercefoto.ro",
        siteUrl: "https://ecommercefoto.ro",
        builtWith: "From scratch - HTML, CSS, JS, PHP, MySQL, Price Calculator, Login/Register, Invoice Generator",
        siteType: "Batch Product Photography Service",
    }
]

export default wpSites;