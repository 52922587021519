import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import SpriteImg from "./wamComponents/SpriteImg";
import ScoreCounter from "./wamComponents/ScoreCounter";

function Wam() {
  const [gameState, setGameState] = useState({ number: null, keyPressed: null });
  const [running, setRunning] = useState(false);
  const intervalRef = useRef(null);
  const [score, setScore] = useState(0);
  const [clickRegistered, setClickRegistered] = useState(false);

  // Generate random number and exclude previous number
  const generateRandomNumber = (min, max, prevNum) => {
    let newNum;
    do {
      newNum = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (newNum === prevNum);
    return newNum;
  };

  // Handle input (keyboard or mouse click)
  const handleInput = (input) => {
    if (!clickRegistered && input === gameState.number?.toString()) {
      setScore((prevScore) => prevScore + 1);
      setClickRegistered(true);
    } else if (!clickRegistered) {
      setScore((prevScore) => (prevScore > 0 ? prevScore - 1 : 0));
      setClickRegistered(true);
    }
    setGameState((prevState) => ({ ...prevState, keyPressed: input }));
  };

  // Handle key down event
  const handleKeyDown = (event) => {
    handleInput(event.key);
  };

  // Handle div click event
  const handleDivClick = (num) => {
    handleInput(num.toString());
  };

  // Start generating random numbers
  const startGenerating = () => {
    if (running) return;
    setRunning(true);
    setScore(0);
    setGameState({ number: null, keyPressed: null });
    setClickRegistered(false);

    const generateNumber = () => {
      setGameState((prevState) => {
        const newNum = generateRandomNumber(0, 9, prevState.number);
        const element = document.getElementById(newNum);
        if (element) {
          element.classList.add('activeSprite');
          setTimeout(() => {
            element.classList.remove('activeSprite');
            element.classList.add('resetSprite');
            setTimeout(() => {
              element.classList.remove('resetSprite');
            }, 50);
          }, 1000);
        }
        setClickRegistered(false);
        return { ...prevState, number: newNum, keyPressed: null };
      });
    };

    const runGameLoop = () => {
      generateNumber();
      intervalRef.current = setTimeout(runGameLoop, 1000);
    };

    runGameLoop();
    setTimeout(stopGenerating, 60000);
  };

  // Stop generating random numbers
  const stopGenerating = () => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
      setRunning(false);
    }
  };

  useEffect(() => {
    if (running) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [running]);

  // Handle touch events for mobile
  useEffect(() => {
    const handleTouchStart = (event) => {
      const touchedElement = document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY);
      if (touchedElement && touchedElement.id) {
        handleDivClick(touchedElement.id);
      }
    };
    if (running) {
      window.addEventListener('touchstart', handleTouchStart);
    }
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, [running]);

  return (
    <div className="wam d-flex">
      <div className="grid-container wackamole">
        {[7, 8, 9, 4, 5, 6, 1, 2, 3, 0].map((num) => (
          <div
            key={num}
            className={classNames('grid-item wrap staticSprite')}
            id={num}
            onClick={() => handleDivClick(num)}
          >
            <SpriteImg numKey={`Numpad${num}`} alt={"wam sprite"} />
          </div>
        ))}
        <ScoreCounter score={score} number={gameState.number} keyPressed={gameState.keyPressed} />
        <div className={classNames('grid-item wrap')} id="controls">
          <button className="startBtn" onClick={startGenerating} disabled={running}>
            Start
          </button><br />
          {/* <button onClick={stopGenerating} disabled={!running}>
            Stop
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Wam;
