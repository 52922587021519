import React from "react";
import logo from "../images/Logo.svg";

function Heading() {
    return <header className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <a href="/"><img className="navbar-brand logo" src={logo} /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
                <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link" href="/websites">Websites</a></li>
                <li className="nav-item"><a className="nav-link" href="/games">Games</a></li>
                <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
            </ul>
        </div>
        </header>;
}

export default Heading;