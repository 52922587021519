import React, { useEffect, useState } from 'react';
import mainImg from '../../images/sprites.jpg';

function SpriteImg(props) {
    const [top, setTop] = useState(-100);

    const imageClick = () => {
        setTop(top + 100);
        setTimeout(() => { setTop(top) }, 500);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === props.numKey) {
                imageClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [top]);

    return (
        <img onClick={imageClick} draggable="false" className='character_spritesheet' style={{ top: `${top}%` }} src={mainImg}/>
    );
}

export default SpriteImg;